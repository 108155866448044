export default {
  en: {
    general: {
      close: "Close",
      create: "Create",
      general: "General",
      advanced: "Advanced",
      activations: "Activations",
      birthData: "Birth Data",
      personality: "Personality",
      design: "Design",
      chart: "Chart",
      details: "Details",
      properties: "Properties",
    },
    error: {
      occurred: "An error occurred",
    },
    menu: {
      application: "Application",
      home: "Home",
      library: "Library",
      language: "Language",
    },
    library: {
      sub: "Select a Chart from the list below to view the details.",
      noElements:
        "You haven't created any Chart yet, Charts you create will appear here.",
    },
    centers: {
      head: "The Head center",
      anja: "The Anja center",
      sacral: "The Sacral center",
      root: "The Root center",
      solarPlexus: "The Solar Plexus center",
      splenic: "The Splenic center",
      ego: "The Ego center",
      g: "The G center",
      throat: "The Throat center",

      headDesc:
        "Are you feeling overwhelmed with pressure to make decisions, having answers, or know something you couldn't possibly have the answer to? The mind is only designed to help us contemplate and think about many different possibilities. You're meant to channel inspiration from the infinite and follow what excites you. Don't limit yourself. ",
      anjaDesc:
        "You're meant to see many perspectives and switch logic styles to show us options that no one else can see yet. Don't feel pigeonholed into thinking, learning, or understanding things the same way others do.",
      sacralDesc:
        "TestaThe majority of the world right now has a defined sacral so you may feel the need to keep working, hustling, creating, etc - but your true strength comes in knowing when enough is enough. Honor your energy and rest when you need to, the world will wait because it requires your unique input. ",
      rootDesc:
        "There is a lot of guilt in this center and the false belief that when you complete something the pressure will be off. This is just adrenaline, it's not reality. Sitting with the pressure and evaluating how you can be comfortable even if something is tugging at you is key. You need to rest and honor the wave of when you have the energy to do something and when you do not. Use the heightened energy to focus on creative pursuits or something that makes you happy - rather than being tied to a never-ending to-do list. ",
      solarPlexusDesc:
        "Emotions are meant to come and go just like the weather. You have an endless amount of empathy and compassion for the world but that doesn't mean you need to sacrifice your own peace and well-being. Hard conversations are the way to release emotions - if you hold onto them too tight, they start to manifest in physical ways. You are brave, strong, and not responsible for anyone else's reactions or emotions.",
      splenicDesc:
        "Staying in relationships, jobs, cities, whatever it is because it feels “safe” is a trap. You have all the knowledge and strength you need to feel safe and provide for yourself regardless of external factors. Something that makes you feel secure but terrible otherwise is only draining you and making you less resilient. ",
      egoDesc:
        'The Ego is deeply materialistic. It is the heart of the family, of the tribal community, and of any company and larger business - "I scratch your back, you scratch mine". It is a powerful motor to survive, the will to provide resources, education, bonds, and everything else that is needed for the community.',
      gDesc:
        "There is only one identity center, and this is the G center. This center is located in the heart space, or chest area, and holds the magnetic monopole. This is a space of magnetism and helps to pull to you what is correct for you and pushes away what is incorrect for you. This center helps give a sense of differentiation between you and what is outside of you. This is where you hold your identity, your concept of self, who you are, and where you are going.",
      throatDesc:
        "Your Throat Center is the portal responsible for the energy in your bodygraph manifesting - as communication or action. Energies of eleven different channels from the six centers that directly connect to the Throat each have a unique voice. The pressures that come from the Head and the Root flow through these six centers to the Throat to share, empower and support humanity.",
    },
    chart: {
      propName: "Chart name",
      propType: "Type",
      propCreationDate: "Creation date",
      propActions: "Actions",
      propCreate: "Create a new Chart",
      propCreateDesc:
        "Insert the required details below to add a new Chart to the Library.",
      propDetailsLabel: "Chart Details",
      propBirthDate: "Birth date",
      propBirthTime: "Birth time",
      propBirthCity: "Birth city",
      propTransitBirthCity: "Transit city",
      propFirstConnection: "First Chart",
      propSecondConnection: "Second Chart",
      propTransitChart: "Comparation chart",
      gotDeleted: "A Chart has been deleted.",
      type: {
        personal: "Personal",
        connection: "Connection",
        transit: "Transit",
      },
      props: {
        type: "Type",
        strategy: "Strategy",
        signature: "Signature",
        nonSelfTheme: "Non-Self Theme",
        authority: "Authority",
        definition: "Definition",
        profile: "Profile",
        activations: {
          line1: "Line one",
          line2: "Line two",
          line3: "Line three",
          line4: "Line four",
          line5: "Line five",
          line6: "Line six",
        },
        birthData: {
          timezone: "Timezone",
          birthDate: "Date of birth",
          personalityDate: "Personality date (UTC)",
          designDate: "Design date (UTC)",
        },
        variable: "Variable",
        determination: "Determination",
        cognition: "Cognition",
        environment: "Environment",
        motivation: "Motivation",
        view: "View",
        incarnationCross: "Incarnation cross"
      },
      values: {
        notAMember: 'You don\'t seem to be a Member',
        notAPremiumMember: 'You don\'t seem to be a Premium Member',
        startFreeTrial: 'Start a Free Trial',
        login: 'LOG IN',
        requiresMember: 'You must be a Member in order to view this page.',
        requiresPremium: 'This Feature requires a Premium membership.',
        gates: 'Gates',
        bodygraph: 'Bodygraph',
        type: {
          manifestingGenerator: "Manifesting Generator",
          pureGenerator: "Pure Generator",
          manifestor: "Manifestor",
          projector: "Projector",
          reflector: "Reflector",
          unknown: ""
        },
        strategy: {
          toRespond: "To Respond",
          toInform: "To Inform",
          waitForInvitation: "Wait for an Invitation",
          waitLunarCycle: "Wait for a Lunar Cycle",
          unknown: ""
        },
        motivation: {
          fear: 'Fear',
          hope: 'Hope',
          desire: 'Desire',
          need: 'Need',
          fault: 'Fault',
          innocence: 'Innocence'
        },
        environment: {
          caves: {
            active: 'Caves/Active',
            passive: 'Caves/Passive'
          },
          markets: {
            active: 'Markets/Active',
            passive: 'Markets/Passive'
          },
          kitchens: {
            active: 'Kitchens/Active',
            passive: 'Kitchens/Passive'
          },
          mountains: {
            active: 'Mountains/Active',
            passive: 'Mountains/Passive'
          },
          valleys: {
            active: 'Valleys/Active',
            passive: 'Valleys/Passive'
          },
          beaches: {
            active: 'Beaches/Active',
            passive: 'Beaches/Passive'
          }
        },
        signature: {
          satisfaction: "Satisfaction",
          peace: "Peace",
          success: "Success",
          surprise: "Surprise",
          unknown: ""
        },
        nonSelfTheme: {
          frustration: "Frustration",
          anger: "Anger",
          bitterness: "Bitterness",
          disappointment: "Disappointment",
          unknown: ""
        },
        definition: {
          single: "Single",
          split: "Split",
          tripleSplit: "Triple split",
          quadrupleSplit: "Quadruple split",
          unknown: ""
        },
        authority: {
          solarPlexus: "Solar plexus (Emotional)",
          sacral: "Sacral",
          splenic: "Splenic",
          environment: "Environment",
          egoManifested: "Ego-Manifested",
          lunar: "Lunar",
          unknown: ""
        },
        activations: {
          line1: "Line one",
          line2: "Line two",
          line3: "Line three",
          line4: "Line four",
          line5: "Line five",
          line6: "Line six",
        },
        view: {
          focused: 'Focused',
          peripheral: 'Peripheral'
        },
        consecutive: "Consecutive",
        alternating: "Alternating",
        open: "Open",
        closed: "Closed",
        hot: "Hot",
        cold: "Cold",
        calm: "Calm",
        nervous: "Nervous",
        high: "High",
        low: "Low",
        direct: "Direct",
        indirect: "Indirect",
        smell: "Smell",
        taste: "Taste",
        externalVision: "External vision",
        internalVision: "Internal vision",
        toFeel: "To Feel",
        touch: "Touch",
        selective: "Selective",
        blending: "Blending",
        internal: "Internal",
        external: "External",
        wet: "Wet",
        dry: "Dry",
        active: "Active",
        passive: "Passive",
        narrow: "Narrow",
        wide: "Wide",
        natural: "Natural",
        artificial: "Artificial",
        communalist: "Communalist",
        separatist: "Separatist",
        theist: "Theist",
        antitheis: "Antitheist",
        leader: "Leader",
        follower: "Follower",
        master: "Master",
        novice: "Novice",
        conditioner: "Conditioner",
        conditioned: "Conditioned",
        observer: "Observer",
        observed: "Observed",
        arrows: {
          active: "Active",
          passive: "Passive",
          observed: "Observed",
          observer: "Observer",
          strategic: "Strategic",
          receptive: "Receptive",
          focused: "Focused",
          peripheral: "Peripheral"
        }
      },
      planetsDefs: {
        Sun: {
          img: "Sun",
          title: "The Sun",
          description: "The Sun represents your life force, purpose, and core essence. It is the center of your being and your unique individuality."
        },
        Earth: {
            img: "Earth",
            title: "The Earth",
            description: "The Earth represents your sense of self, identity, and the way you interact with others and the world around you."
        },
        Moon: {
            img: "Moon",
            title: "The Moon",
            description: "The Moon represents your emotions, instincts, and inner world. It influences your emotional responses and how you process feelings."
        },
        NorthNode: {
            img: "North Node",
            title: "The North Node",
            description: "The North Node points to your life's purpose and the direction you should move towards to fulfill your potential and find fulfillment."
        },
        SouthNode: {
            img: "South Node",
            title: "The South Node",
            description: "The South Node reflects your past experiences and the qualities you have developed, but also the patterns you need to release to grow."
        },
        Mercury: {
            img: "Mercury",
            title: "Mercury",
            description: "Mercury governs communication, thinking patterns, and how you express yourself intellectually."
        },
        Venus: {
            img: "Venus",
            title: "Venus",
            description: "Venus is associated with love, relationships, beauty, and what you value in life."
        },
        Mars: {
            img: "Mars",
            title: "Mars",
            description: "Mars represents energy, action, passion, and how you assert yourself in the world."
        },
        Jupiter: {
            img: "Jupiter",
            title: "Jupiter",
            description: "Jupiter signifies expansion, growth, abundance, and the areas of life where you experience good fortune and blessings."
        },
        Saturn: {
            img: "Saturn",
            title: "Saturn",
            description: "Saturn represents discipline, responsibility, challenges, and the areas where you need to work hard to achieve success and mastery."
        },
        Uranus: {
            img: "Uranus",
            title: "Uranus",
            description: "Uranus is associated with innovation, individuality, and unexpected change, bringing excitement and revolution to your life."
        },
        Neptune: {
            img: "Neptune",
            title: "Neptune",
            description: "Neptune governs dreams, intuition, spirituality, and how you connect with the unseen realms and collective consciousness."
        },
        Pluto: {
            img: "Pluto",
            title: "Pluto",
            description: "Pluto represents transformation, power, and deep inner changes that lead to personal growth and evolution."
        }
      },
      linesDefs: {
          1: "What is its main essence? Having the first line in our Bodygraph, we get acquainted with some area of knowledge and begin to study it, sinking into this process completely. We study every feature and nuance of the subject we are interested in. But we are not sure of anything. But as we research, we become aware and authoritarian.",
          2: "Here we see a fanatical study of our chosen field. And now we are attracted only to some narrow field of study. For example, not just astrology, but specifically relationship astrology. And we begin to willingly move in that direction, fending off barriers from those who can’t understand why we need it.",
          3: "Here we see constant trial and error. They are unavoidable. Here we begin to work fully, for the third line is related to material processes, and we discover how many things in this world do not work or work according to incorrect schemes. Then we put forward our own theories, refuting or confirming them. We actively interact with other people, making acquaintances or getting out of them. We look for our own people.",
          4: "These are like-minded people. These are the people we go along with. We influence them with our reflections, our discoveries, our research, our gift and authority. Through them we make useful new connections, and they actively support us in this, bringing a lot of new opportunities into our lives.",
          5: "We are already so good at what we do that we can go public. We’re ready to talk about our achievements and what interests us. We talk passionately and knowledgeably about what matters to us, essentially promoting what we do. We are so good at what we do that we can explain complex things on our fingers and make them understandable even to a child. Here we are dealing with universalization.",
          6: "This is the pinnacle of professionalism. Now we are the Role Model, the person who is set as an example to others. And this is where we come to the First Line again. We become Authoritative in our business, entering a new round of the spiral."
      },
      centersGatesDefs: {
          1: { number: 1, title: "The Gate 1 of Self Expression", definition: "Gate 1 Self Expression is the gate of the artist and actor. Such people always attract attention, although they love to create in solitude. Their philosophy is to do everything in their own special way. Such creativity has an ebb and flow. It can flare up or disappear in an instant. People with 1 gate create for the sake of creativity itself, because they cannot otherwise. Internally, they expect recognition for their creativity." },
          2: { number: 2, title: "The Gate 2 of Higher Knowledge", definition: "People with activated Gate 2 of Higher Knowledge are people with a subtle foreboding of an innovative and successful direction in life. Throughout life, they are driven by a craving for beauty, love and creative work." },
          3: { number: 3, title: "The Gate 3 of Ordering", definition: "People with The Gate 3 of Ordering want to work on something new. Their river has no banks and no channel. If they do not expect opportunities from life, but invent them themselves, by this they only intensify the chaos around them, and their innovative potential cannot manifest itself. In the channel of Mutation 3-60, it is the 60th gate that is the fire on which the mutation is being prepared. Therefore, it is important for the 3rd gate to learn patience and clarity that the maturation process has its own timing, which should not be accelerated artificially. Patience allows the 3rd gate to give birth to something that will have lasting practical value." },
          4: { number: 4, title: "The Gate 4 of Formulation", definition: "People with The Gate 4 of Formulation appreciate difficult and deep questions. It is important to remember that their answers are only guesses that need to be tested empirically. Therefore, not all of their answers are valuable. Some, on the other hand, may be useless or wrong. Their need to provide answers is related to the need to free themselves from the pressure of thoughts that they feel in their head." },
          5: { number: 5, title: "The Gate 5 of Fixed patterns", definition: "It is important for people with The Gate 5 of Fixed patterns to maintain their natural temporal rhythm of life. The easiest way is for them to work in a specific rhythm and a familiar schedule. They connect people with the uniform rhythm of the universe and through this bring them a sense of security and timeliness." },
          6: { number: 6, title: "The Gate 6 of Friction", definition: "People with The Gate 6 of Friction are created to distinguish between sexuality and feelings, to be aware of emotionally and sexually." },
          7: { number: 7, title: "The Gate 7 of Self Roles", definition: "People with The Gate 7 of Self Roles are endowed with potential and passion for leadership. They lead to a better future by their example. It is important for them to be recognized in this role, chosen, invited to the leadership." },
          8: { number: 8, title: "The Gate 8 of Facilitation", definition: "The gift of people with the Gate 8 of Facilitation is to draw attention to the truth, to the creativity and love of the Creator." },
          9: { number: 9, title: "The Gate 9 of Focus", definition: "People with the Gate 9 of Focus have the ability to concentrate on working with details and nuances for a long time, as long as they are not distracted by anything else." },
          10: { number: 10, title: "The Gate 10 of Self Behavior", definition: "The whole life for people with The Gate 10 of Self Behavior is an expression of their uniqueness and love for themselves, enjoying their uniqueness." },
          11: { number: 11, title: "The Gate 11 of Ideas", definition: "The mind of people with the Gate 11 of Ideas is the source of a variety of ideas, most of which are not meant to be expressed." },
          12: { number: 12, title: "The Gate 12 of Caution", definition: "People with the Gate 12 of Caution are cautious about whether other people understand them correctly. They are waiting for the right moment when they can influence others. And those who are susceptible to their influence." },
          13: { number: 13, title: "The Gate 13 of Listener", definition: "People with the Gate 13 of Listener are natural listeners. They guide others through experiences they have witnessed." },
          14: { number: 14, title: "The Gate 14 of Power Skills", definition: "The creative potential of people with the Gate 14 of Power Skills is to passionately love their work and find application for their material talents." },
          15: { number: 15, title: "The Gate 15 of Extremum", definition: "People with the Gate 15 of Extremum with the need to change their rhythm of life in response to changing circumstances. In society, they adhere to various extremes in self-expression and lifestyle and, like no one, are able to take extremes in others. Their actions are guided by love for humanity." },
          16: { number: 16, title: "The Gate 16 of Skills", definition: "What they already know is never enough for people with the Gate 16 of Skills. They experiment with different skills, abilities and eventually achieve mastery in them." },
          17: { number: 17, title: "The Gate 17 of Opinions", definition: "People with the Gate 17 of Opinions have their own opinions on many issues. They make various assumptions about topics of concern to them. Their gift is to foresee the future by observing the present. A well-built opinion, confirmed by details and facts, can serve many people, show systematically and logically what is happening now and predict the future." },
          18: { number: 18, title: "The Gate 18 of Correction", definition: "The gift of people with the Gate 18 of Correction is in everything and always to see defects, imperfections and imperfections in order to correct, improve them. To make the world more perfect and people’s lives safe." },
          19: { number: 19, title: "The Gate 19 of Need", definition: "People with The Gate 19 of Need financially and emotionally successful interactions with other people." },
          20: { number: 20, title: "The Gate 20 of Now", definition: "People with The Gate 20 of Now only see the present moment. They are aware of everything important at the moment of “Now”. This moment connects them to the present as much as possible and is recognized by others as wakefulness." },
          21: { number: 21, title: "The Gate 21 of Hunter", definition: "Material independence is important for people with The Gate 21 of Hunter. Leaders by nature. They flourish when they are able to control others, and when they gain such freedom that no one can control them." },
          22: { number: 22, title: "The Gate 22 of Openness", definition: "People with The Gate 22 of Openness are always under incredible pressure to express their feelings, to influence them on the emotions and sense of self of other people. With their emotional openness, they reveal other people, make them receptive to the adoption of new, transformational knowledge." },
          23: { number: 23, title: "The Gate 23 of Assimilation", definition: "The recognition and success of people with The Gate 23 of Assimilation depends on the timeliness of what they say, on their skill in word, communication skills." },
          24: { number: 24, title: "The Gate 24 of Rationalization", definition: "People with the Gate 24 of Rationalization constantly return to the same mystical questions in an attempt to comprehend them and find practical solutions and applications for them." },
          25: { number: 25, title: "The Gate 25 of Self Spirit", definition: "People with the Gate 25 of Self Spirit connect others with their truth through the manifestation of innocence and unconditional acceptance of the individuality of each, regardless of the circumstances." },
          26: { number: 26, title: "The Gate 26 of Egoist", definition: "The goal and gift of people with the Gate 26 of Egoist is to receive the maximum reward for the minimum effort. It is beneficial to use your ingenuity, audacity and resourcefulness to the benefit of yourself and your community." },
          27: { number: 27, title: "The Gate 27 of Care", definition: "People with the Gate 27 of Care are born to protect, protect and support themselves and others. They derive satisfaction from activities that enable them to take care of themselves and others." },
          28: { number: 28, title: "The Gate 28 of Player", definition: "People with the Gate 28 of Player are ready to risk a lot for the sake of their goal and meaning in life. They are looking for a goal to which they can devote themselves entirely." },
          29: { number: 29, title: "The Gate 29 of Concord", definition: "People with the Gate 29 of Concord can trust a new life opportunity, surrender to it directly and irrevocably, completely not knowing where it will lead them." },
          30: { number: 30, title: "The Gate 30 of Feelings", definition: "People with the Gate 30 of Feelings will never be free from desires. Desires drive them and there is nothing personal about it." },
          31: { number: 31, title: "The Gate 31 of Leadership", definition: "A person with the Gate 31 of Leadership is an influential leader who calls for systematic action, order, and discipline so that the future of his followers is safe and joyful." },
          32: { number: 32, title: "The Gate 32 of Continuity", definition: "People with an innate instinct for continuous material growth of their personal or social." },
          33: { number: 33, title: "The Gate 33 of Privacy", definition: "Only in solitude people with the Gate 33 of Privacy are able to see the value of what happened to them, to highlight the essence and benefits for themselves and those around them." },
          34: { number: 34, title: "The Gate 34 of Force", definition: "The actions and interests of people with the Gate 34 of Force are independent and self-centered. First of all, they want to do what will strengthen and satisfy themselves. This is how they bring the most value to others and feel the thrill of being busy." },
          35: { number: 35, title: "The Gate 35 of Changes", definition: "People with the Gate 35 of Changes try many different things in life and not all hands become masters. They lead people or projects out of crises and stagnation into progress and rapid development." },
          36: { number: 36, title: "The Gate 36 of Crisis", definition: "People with the Gate 36 of Crisis grow through deep emotional experiences and help other people grow in what they went through themselves." },
          37: { number: 37, title: "The Gate 37 of Friendship", definition: "For people with the Gate 37 of Friendship, it is important that the family or community recognizes in them the value of their magnetism, emotional support, and volitional qualities." },
          38: { number: 38, title: "The Gate 38 of Fighter", definition: "People with Gate 38 Fighter show their best qualities when confronting difficulties. Warriors and fighters for their highest goal. They resist any external influence if it separates them from their Highest goal." },
          39: { number: 39, title: "The Gate 39 of Provocateur", definition: "The task of people with the Gate 39 of Provocateur is to provoke the Spirit of others into material and spiritual abundance. People with the Gate 39 provoke the emotions of other people, and this is how their Spirit is tested. Provocations can be very different. They can provoke even by their inaction or silence. The provocation gate also carries the quality of flirting. If the reaction to their provocation is negative, then the provocateur is not in resonance with the spirit of the other person. A 39-year-old shouldn’t take it personally. It is not worth doing this to their environment." },
          40: { number: 40, title: "The Gate 40 of Loneliness", definition: "It is important for people with the Gate 40 of Loneliness to find a healthy balance between work and rest, to find time for themselves and their desires." },
          41: { number: 41, title: "The Gate 41 of Compression", definition: "People with the Gate 41 of Compression never stop wanting new feelings. Their dreams come true when they focus only on them and cut out the rest." },
          42: { number: 42, title: "The Gate 42 of Growth", definition: "People with the Gate 42 of Growth have pressure to complete what they started, to strengthen others, and through this to achieve growth, development and wisdom." },
          43: { number: 43, title: "The Gate 43 of Insight", definition: "People with the Gate 43 of Insight think creatively, sometimes brilliantly. They catch insights on topics of interest. Their insights go beyond logic and common sense, which is why for others they may not be obvious, even bizarre. These people often find simple solutions to popular problems." },
          44: { number: 44, title: "The Gate 44 of Vigilance", definition: "People with the Gate 44 of Vigilance have an instinctive knowledge of the business ability and material needs of others. Feel the directions that lead to success, and the people with whom this success is possible." },
          45: { number: 45, title: "The Gate 45 of Gatherer", definition: "People with the Gate 45 of Gatherer lead the community to material prosperity and well-being. Unite people around a common goal. They increase the education and competence of their people. They bring a sense of synergy to their Kingdom." },
          46: { number: 46, title: "The Gate 46 of Self Determination", definition: "People with the Gate 46 of Self Determination are the favorites of fortune, when they let go of any expectations – as everything should be, and keep the body healthy. There comes a point when it is the body that helps them succeed where others fail." },
          47: { number: 47, title: "The Gate 47 of Realization", definition: "People with the Gate 47 of Realization are always thinking about life. They relate it to personal experience and reveal previously hidden meanings. They tune people’s thoughts in a more positive way, help others to see positive meanings in the most unpleasant events and life situations." },
          48: { number: 48, title: "The Gate 48 of Depths", definition: "People with the Gate 48 of Depths may not notice their wisdom until others pay attention to it. They are recognized for a deep perception of people and life, for an understanding of the root causes." },
          49: { number: 49, title: "The Gate 49 of Principles", definition: "The Gate 49 of Principles establish a fair distribution of resources within the community. These principles help to separate us from others. Personal boundaries are a prerequisite for their health and well-being. People with the Gate 49 defend the principles of justice in society and can ignite a revolution if their principles are violated. If people’s behavior is not in accordance with their principles, the energy of the 49th gate will insist on their observance, punish the apostates, and even expel from the community." },
          50: { number: 50, title: "The Gate 50 of Values", definition: "People with the Gate 50 of Values bring a sense of responsibility, law and order to society. Establish the rules of safety and well-being necessary for order and safety in society." },
          51: { number: 51, title: "The Gate 51 of Shock", definition: "People with the Gate 51 of Shock temper and awaken their Spirit when they manifest themselves boldly and recklessly. So they awaken in others their uniqueness, become the best or the first in their business." },
          52: { number: 52, title: "The Gate 52 of Inaction", definition: "People with the Gate 52 of Inaction have tremendous strength of endurance and concentration. The most important thing for them is to understand what to direct this endurance and focus on. It is important for them to feel useful and serve the community." },
          53: { number: 53, title: "The Gate 53 of Beginning", definition: "People with the Gate 53 of Beginning are under constant pressure to start new, regardless of the changes that new beginnings may bring. People with the Gate 53 of Beginnings have great potential for mutation. From time to time, they give impetus to the beginning of a new business or process and thus bring changes to the world." },
          54: { number: 54, title: "The Gate 54 of Ambition", definition: "People with the Gate 54 of Ambition have a powerful craving to become financially successful, to climb higher on the social ladder – material or spiritual. People with the Gate 54 have big ambitions. They enjoy doing something great and ambitious. They want to jump over their heads. They want to climb to the very top of the ladder of social success." },
          55: { number: 55, title: "The Gate 55 of Spirit", definition: "Deeply melancholic, musical and romantic people with the Gate 55 of Spirit who are driven by the desire for abundance in the spiritual or material. It is important for them to learn to respect the changeability of their moods and the intensity of their feelings. They deeply feel emotions and influence them on other people." },
          56: { number: 56, title: "The Gate 56 of Stimulation", definition: "People with the Gate 56 of Stimulation use the experience of their past to express it as an idea or concept and to stimulate others with it. People with the Gate 56 bring meaningful ideas into the world, which are often expressed in the form of stories. They are fascinating storytellers and teachers who are able to make sense of information or events and extract from them the very essence, and then share it with others. What they say is easy to remember." },
          57: { number: 57, title: "The Gate 57 of Intuitive Insight", definition: "People with the Gate 57 of Intuitive Insight guarantee their survival, health and material well-being by trusting the prompts of their own intuition." },
          58: { number: 58, title: "The Gate 58 of Vitality", definition: "People with the Gate 58 of Vitality people love to challenge authority simply because they enjoy it. Most of all, they rejoice when they make this world perfect, correct what hinders it." },
          59: { number: 59, title: "The Gate 59 of Sexuality", definition: "People with the Gate 59 of Sexuality in communication with others easily and naturally establish trusting intimacy or intimacy. They are genetically disposed to reproduce children or create the fruits of creativity. People with the Gate 59 are seducers. The sacred power of sexuality is available to them. It can manifest itself in the desire to conceive children or unite in active alliances, building trusting relationships that are not related to intimacy." },
          60: { number: 60, title: "The Gate 60 of Acceptance", definition: "People with the Gate 60 of Acceptance have the potential to transform the way things are. They bring innovation and knowledge to the world that mutate the community." },
          61: { number: 61, title: "The Gate 61 of Mystery", definition: "People with the Gate 61 of Mystery are pressured by thoughts to learn the unknown, the hidden, to unravel the mystery of Life." },
          62: { number: 62, title: "The Gate 62 of Details", definition: "People with the Gate 62 of Details are interested in details and facts, and are very attentive to them. They want to see the logic in what is happening and be able to explain it to others." },
          63: { number: 63, title: "The Gate 63 of Doubt", definition: "People with the Gate 63 of Doubt are the most skeptical by nature. They question everything, even the most convincing, if they find logical inconsistencies or gaps in it. People with the Gate 63 test logical statements for strength. It is with them that all logical analysis begins. These people are looking for answers to their logical doubts about something." },
          64: { number: 64, title: "The Gate 64 of Confusion", definition: "People with the Gate 64 of Confusion are under constant pressure to comprehend the meaning in the events of the past." }
      },
      crosses: {
        "gius_44_24_7_13": "Cross of Alertness Junction 44/24 7/13",
        "gius_54_53_32_42": "Cross of Ambition Junction 54/53 32/42",
        "gius_12_11_25_46": "Cross of Articulation Junction 12/11 25/46",
        "gius_23_43_30_29": "Cross of Assimilation Junction 23/43 30/29",
        "gius_37_40_5_35": "Cross of Pact Junction 37/40 5/35",
        "gius_53_54_42_32": "Cross of Beginning Junction 53/54 42/32",
        "gius_10_15_18_17": "Cross of Behavior Junction 10/15 18/17",
        "gius_27_28_19_33": "Cross of Care Junction 27/28 19/33",
        "gius_29_30_20_34": "Cross of Commitment Junction 29/30 20/34",
        "gius_42_32_60_56": "Cross of Completion Junction 42/32 60/56",
        "gius_6_36_15_10": "Cross of Conflict Junction 6/36 15/10",
        "gius_64_63_45_26": "Cross of Confusion Junction 64/63 45/26",
        "gius_8_14_55_59": "Cross of Contribution Junction 8/14 55/59",
        "gius_21_48_54_53": "Cross of Control Junction 21/48 54/53",
        "gius_18_17_39_38": "Cross of Correction Junction 18/17 39/38",
        "gius_36_6_10_15": "Cross of Crisis Junction 36/6 10/15",
        "gius_40_37_35_5": "Cross of Denial Junction 40/37 35/5",
        "gius_48_21_53_54": "Cross of Depth Junction 48/21 53/54",
        "gius_62_61_3_50": "Cross of Detail Junction 62/61 3/50",
        "gius_63_64_26_45": "Cross of Doubt Junction 63/64 26/45",
        "gius_2_1_49_4": "Cross of Driver Junction 2/1 49/4",
        "gius_14_8_59_55": "Cross of Empowerment Junction 14/8 59/55",
        "gius_35_5_22_47": "Cross of Experience Junction 35/5 22/47",
        "gius_16_9_63_64": "Cross of Experimentation Junction 16/9 63/64",
        "gius_15_10_17_18": "Cross of Extremes Junction 15/10 17/18",
        "gius_41_31_44_24": "Cross of Fantasy Junction 41/31 44/24",
        "gius_30_29_34_20": "Cross of Destinies Junction 30/29 34/20",
        "gius_9_16_64_63": "Cross of Focus Junction 9/16 64/63",
        "gius_4_49_8_14": "Cross of Formulation Junction 4/49 8/14",
        "gius_22_47_11_12": "Cross of Grace Junction 22/47 11/12",
        "gius_5_35_47_22": "Cross of Habits Junction 5/35 47/22",
        "gius_11_12_46_25": "Cross of Ideas Junction 11/12 46/25",
        "gius_31_41_24_44": "Cross of Influence Junction 31/41 24/44",
        "gius_25_46_58_52": "Cross of Innocence Junction 25/46 58/52",
        "gius_43_23_29_30": "Cross of Inner Vision Junction 43/23 29/30",
        "gius_7_13_23_43": "Cross of Interaction Junction 7/13 23/43",
        "gius_57_51_62_61": "Cross of Intuition Junction 57/51 62/61",
        "gius_60_56_28_27": "Cross of Limitation Junction 60/56 28/27",
        "gius_13_7_43_23": "Cross of Listening Junction 13/7 43/23",
        "gius_55_59_9_16": "Cross of Fluctuating Mood States Junction 55/59 9/16",
        "gius_3_50_41_31": "Cross of Mutation Junction 3/50 41/31",
        "gius_19_33_1_2": "Cross of Needs Junction 19/33 1/2",
        "gius_20_34_37_40": "Cross of Now Junction 20/34 37/40",
        "gius_17_18_38_39": "Cross of Opinions Junction 17/18 38/39",
        "gius_38_39_57_51": "Cross of Opposition Junction 38/39 57/51",
        "gius_47_22_12_11": "Cross of Oppression Junction 47/22 12/11",
        "gius_45_26_36_6": "Cross of Possession Junction 45/26 36/6",
        "gius_34_20_40_37": "Cross of Power Junction 34/20 40/37",
        "gius_49_4_14_8": "Cross of Principles Junction 49/4 14/8",
        "gius_39_38_51_57": "Cross of Provocation Junction 39/38 51/57",
        "gius_24_44_13_7": "Cross of Rationalization Junction 24/44 13/7",
        "gius_33_19_2_1": "Cross of Retreat Junction 33/19 2/1",
        "gius_28_27_33_19": "Cross of Risks Junction 28/27 33/19",
        "gius_1_2_4_49": "Cross of Self-Expression Junction 1/2 4/49",
        "gius_46_25_52_58": "Cross of Serendipity Junction 46/25 52/58",
        "gius_51_57_61_62": "Cross of Shock Junction 51/57 61/62",
        "gius_52_58_21_48": "Cross of Inaction Junction 52/58 21/48",
        "gius_56_60_27_28": "Cross of Stimulation Junction 56/60 27/28",
        "gius_59_55_16_9": "Cross of Strategy Junction 59/55 16/9",
        "gius_61_62_50_3": "Cross of Thought Junction 61/62 50/3",
        "gius_26_45_6_36": "Cross of Prestidigitation Junction 26/45 6/36",
        "gius_50_3_31_41": "Cross of Values Junction 50/3 31/41",
        "gius_58_52_48_21": "Cross of Vitality Junction 58/52 48/21",
    
        "sini_27_28_19_33": "Left Angle Cross of Alignment 27/28 19/33",
        "sini_28_27_33_19": "Left Angle Cross of Alignment (2) 28/27 33/19",
        "sini_31_41_24_44": "Left Angle Cross of Alpha 31/41 24/44",
        "sini_41_31_44_24": "Left Angle Cross of Alpha (2) 41/31 44/24",
        "sini_51_57_61_62": "Left Angle Cross of the Messenger 51/57 61/62",
        "sini_57_51_62_61": "Left Angle Cross of the Messenger (2) 57/51 62/61",
        "sini_45_26_36_6": "Left Angle Cross of Confrontation 45/26 36/6",
        "sini_26_45_6_36": "Left Angle Cross of Confrontation (2) 26/45 6/36",
        "sini_53_54_42_32": "Left Angle Cross of Cycles 53/54 42/32",
        "sini_54_53_32_42": "Left Angle Cross of Cycles (2) 54/53 32/42",
        "sini_23_43_29_30": "Left Angle Cross of Dedication 23/43 29/30",
        "sini_43_23_30_29": "Left Angle Cross of Dedication (2) 43/23 30/29",
        "sini_2_1_49_4": "Left Angle Cross of Challenge 2/1 49/4",
        "sini_1_2_4_49": "Left Angle Cross of Challenge (2) 1/2 4/49",
        "sini_52_58_21_48": "Left Angle Cross of Demands 52/58 21/48",
        "sini_58_52_48_21": "Left Angle Cross of Demands (2) 58/52 48/21",
        "sini_56_60_27_28": "Left Angle Cross of Distraction 56/60 27/28",
        "sini_60_56_28_27": "Left Angle Cross of Distraction (2) 60/56 28/27",
        "sini_63_64_26_45": "Left Angle Cross of Dominion 63/64 26/45",
        "sini_64_63_45_26": "Left Angle Cross of Dominion (2) 64/63 45/26",
        "sini_20_34_37_40": "Left Angle Cross of Duality 20/34 37/40",
        "sini_34_20_40_37": "Left Angle Cross of Duality (2) 34/20 40/37",
        "sini_12_11_25_46": "Left Angle Cross of Education 12/11 25/46",
        "sini_11_12_46_25": "Left Angle Cross of Education (2) 11/12 46/25",
        "sini_21_48_54_53": "Left Angle Cross of Engagement 21/48 54/53",
        "sini_48_21_53_54": "Left Angle Cross of Engagement (2) 48/21 53/54",
        "sini_46_25_52_58": "Left Angle Cross of Healing 46/25 52/58",
        "sini_25_46_58_52": "Left Angle Cross of Healing (2) 25/46 58/52",
        "sini_16_9_63_64": "Left Angle Cross of Identification 16/9 63/64",
        "sini_9_16_64_63": "Left Angle Cross of Identification (2) 9/16 64/63",
        "sini_24_44_13_7": "Left Angle Cross of Incarnation 24/44 13/7",
        "sini_44_24_7_13": "Left Angle Cross of Incarnation (2) 44/24 7/13",
        "sini_39_38_51_57": "Left Angle Cross of Individualism 39/38 51/57",
        "sini_38_39_57_51": "Left Angle Cross of Individualism (2) 38/39 57/51",
        "sini_30_29_34_20": "Left Angle Cross of Industry 30/29 34/20",
        "sini_29_30_20_34": "Left Angle Cross of Industry (2) 29/30 20/34",
        "sini_22_47_11_12": "Left Angle Cross of Informing 22/47 11/12",
        "sini_47_22_12_11": "Left Angle Cross of Informing (2) 47/22 12/11",
        "sini_42_32_60_56": "Left Angle Cross of Limitation 42/32 60/56",
        "sini_32_42_56_60": "Left Angle Cross of Limitation (2) 32/42 56/60",
        "sini_13_7_43_23": "Left Angle Cross of Masks 13/7 43/23",
        "sini_7_13_23_43": "Left Angle Cross of Masks (2) 7/13 23/43",
        "sini_37_40_5_35": "Left Angle Cross of Migration 37/40 5/35",
        "sini_40_37_35_5": "Left Angle Cross of Migration (2) 40/37 35/5",
        "sini_62_61_3_50": "Left Angle Cross of Obscuring 62/61 3/50",
        "sini_61_62_50_3": "Left Angle Cross of Obscuring (2) 61/62 50/3",
        "sini_36_6_10_15": "Left Angle Cross of Mundane Plan 36/6 10/15",
        "sini_6_36_15_10": "Left Angle Cross of Mundane Plan (2) 6/36 15/10",
        "sini_15_10_17_18": "Left Angle Cross of Prevention 15/10 17/18",
        "sini_10_15_18_17": "Left Angle Cross of Prevention (2) 10/15 18/17",
        "sini_33_19_2_1": "Left Angle Cross of Refinement 33/19 2/1",
        "sini_19_33_1_2": "Left Angle Cross of Refinement (2) 19/33 1/2",
        "sini_49_4_14_8": "Left Angle Cross of Revolution 49/4 14/8",
        "sini_4_49_8_14": "Left Angle Cross of Revolution (2) 4/49 8/14",
        "sini_35_5_22_47": "Left Angle Cross of Separation 35/5 22/47",
        "sini_5_35_47_22": "Left Angle Cross of Separation (2) 5/35 47/22",
        "sini_55_59_9_16": "Left Angle Cross of Spirit 55/59 9/16",
        "sini_59_55_16_9": "Left Angle Cross of Spirit (2) 59/55 16/9",
        "sini_8_14_55_59": "Left Angle Cross of Uncertainty 8/14 55/59",
        "sini_14_8_59_55": "Left Angle Cross of Uncertainty (2) 14/8 59/55",
        "sini_17_18_38_39": "Left Angle Cross of Uprising 17/18 38/39",
        "sini_18_17_39_38": "Left Angle Cross of Uprising (2) 18/17 39/38",
        "sini_3_50_41_31": "Left Angle Cross of Wishes 3/50 41/31",
        "sini_50_3_31_41": "Left Angle Cross of Wishes (2) 50/3 31/41",
    
        "destro_63_64_5_35": "Right Angle Cross of Consciousness 63/64 5/35",
        "destro_35_5_63_64": "Right Angle Cross of Consciousness (2) 35/5 63/64",
        "destro_64_63_35_5": "Right Angle Cross of Consciousness (3) 64/63 35/5",
        "destro_5_35_64_63": "Right Angle Cross of Consciousness (4) 5/35 64/63",
        "destro_30_29_14_8": "Right Angle Cross of Contagion 30/29 14/8",
        "destro_8_14_30_29": "Right Angle Cross of Contagion (2) 8/14 30/29",
        "destro_29_30_8_14": "Right Angle Cross of Contagion (3) 29/30 8/14",
        "destro_14_8_29_30": "Right Angle Cross of Contagion (4) 14/8 29/30",
        "destro_36_6_11_12": "Right Angle Cross of Eden 36/6 11/12",
        "destro_12_11_36_6": "Right Angle Cross of Eden (2) 12/11 36/6",
        "destro_6_36_12_11": "Right Angle Cross of Eden (3) 6/36 12/11",
        "destro_11_12_6_36": "Right Angle Cross of Eden (4) 11/12 6/36",
        "destro_49_4_43_23": "Right Angle Cross of Explanation 49/4 43/23",
        "destro_23_43_49_4": "Right Angle Cross of Explanation (2) 23/43 49/4",
        "destro_4_49_23_43": "Right Angle Cross of Explanation (3) 4/49 23/43",
        "destro_43_23_4_49": "Right Angle Cross of Explanation (4) 43/23 4/49",
        "destro_24_44_19_33": "Right Angle Cross of Four Paths 24/44 19/33",
        "destro_33_19_24_44": "Right Angle Cross of Four Paths (2) 33/19 24/44",
        "destro_44_24_33_19": "Right Angle Cross of Four Paths (3) 44/24 33/19",
        "destro_19_33_44_24": "Right Angle Cross of Four Paths (4) 19/33 44/24",
        "destro_3_50_60_56": "Right Angle Cross of Laws 3/50 60/56",
        "destro_56_60_3_50": "Right Angle Cross of Laws (2) 56/60 3/50",
        "destro_50_3_56_60": "Right Angle Cross of Laws (3) 50/3 56/60",
        "destro_60_56_50_3": "Right Angle Cross of Laws (4) 60/56 50/3",
        "destro_42_32_61_62": "Right Angle Cross of Maya 42/32 61/62",
        "destro_62_61_42_32": "Right Angle Cross of Maya (2) 62/61 42/32",
        "destro_32_42_62_61": "Right Angle Cross of Maya (3) 32/42 62/61",
        "destro_61_62_32_42": "Right Angle Cross of Maya (4) 61/62 32/42",
        "destro_51_57_54_53": "Right Angle Cross of Penetration 51/57 54/53",
        "destro_53_54_51_57": "Right Angle Cross of Penetration (2) 53/54 51/57",
        "destro_57_51_53_54": "Right Angle Cross of Penetration (3) 57/51 53/54",
        "destro_54_53_57_51": "Right Angle Cross of Penetration (4) 54/53 57/51",
        "destro_37_40_9_16": "Right Angle Cross of Planning 37/40 9/16",
        "destro_16_9_37_40": "Right Angle Cross of Planning (2) 16/9 37/40",
        "destro_40_37_16_9": "Right Angle Cross of Planning (3) 40/37 16/9",
        "destro_9_16_40_37": "Right Angle Cross of Planning (4) 9/16 40/37",
        "destro_22_47_26_45": "Right Angle Cross of Sovereignty 22/47 26/45",
        "destro_45_26_22_47": "Right Angle Cross of Sovereignty (2) 45/26 22/47",
        "destro_47_22_45_26": "Right Angle Cross of Sovereignty (3) 47/22 45/26",
        "destro_26_45_47_22": "Right Angle Cross of Sovereignty (4) 26/45 47/22",
        "destro_17_18_58_52": "Right Angle Cross of Service 17/18 58/52",
        "destro_52_58_17_18": "Right Angle Cross of Service (2) 52/58 17/18",
        "destro_18_17_52_58": "Right Angle Cross of Service (3) 18/17 52/58",
        "destro_58_52_18_17": "Right Angle Cross of Service (4) 58/52 18/17",
        "destro_55_59_34_20": "Right Angle Cross of Sleeping Phoenix 55/59 34/20",
        "destro_20_34_55_59": "Right Angle Cross of Sleeping Phoenix (2) 20/34 55/59",
        "destro_59_55_20_34": "Right Angle Cross of Sleeping Phoenix (3) 59/55 20/34",
        "destro_34_20_59_55": "Right Angle Cross of Sleeping Phoenix (4) 34/20 59/55",
        "destro_13_7_1_2": "Right Angle Cross of Sphinx 13/7 1/2",
        "destro_2_1_13_7": "Right Angle Cross of Sphinx (2) 2/1 13/7",
        "destro_7_13_2_1": "Right Angle Cross of Sphinx (3) 7/13 2/1",
        "destro_1_2_7_13": "Right Angle Cross of Sphinx (4) 1/2 7/13",
        "destro_21_48_38_39": "Right Angle Cross of Tension 21/48 38/39",
        "destro_39_38_21_48": "Right Angle Cross of Tension (2) 39/38 21/48",
        "destro_48_21_39_38": "Right Angle Cross of Tension (3) 48/21 39/38",
        "destro_38_39_48_21": "Right Angle Cross of Tension (4) 38/39 48/21",
        "destro_27_28_41_31": "Right Angle Cross of Unexpected 27/28 41/31",
        "destro_31_41_27_28": "Right Angle Cross of Unexpected (2) 31/41 27/28",
        "destro_28_27_31_41": "Right Angle Cross of Unexpected (3) 28/27 31/41",
        "destro_41_31_28_27": "Right Angle Cross of Unexpected (4) 41/31 28/27",
        "destro_25_46_10_15": "Right Angle Cross of Cup of Love 25/46 10/15",
        "destro_15_10_25_46": "Right Angle Cross of Cup of Love (2) 15/10 25/46",
        "destro_46_25_15_10": "Right Angle Cross of Cup of Love (3) 46/25 15/10",
        "destro_10_15_46_25": "Right Angle Cross of Cup of Love (4) 10/15 46/25"
      }
    },
  },
};
