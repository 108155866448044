<template>
  <v-app :class="{ 'dark-mode': darkMode, 'light-mode': !darkMode }">
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
          class="mb-4"
        >
          <h4 class="my-2">{{ $t("menu.application") }}</h4>
          <v-list-item
            v-for="item in menuItems"
            :key="item.name"
            @click="performNavigation(item)"
          >
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.label }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-divider />
        <h4 class="my-2">{{ $t("menu.language") }}</h4>
        <v-list-item @click="loadLanguageAsync('en')">
          <v-list-item-icon>
            <i
              class="country-flag"
              style="
                background-image: url('https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f8.png');
              "
            />
          </v-list-item-icon>
          <v-list-item-title> English </v-list-item-title>
        </v-list-item>
        <v-list-item @click="loadLanguageAsync('it')">
          <v-list-item-icon>
            <i
              class="country-flag"
              style="
                background-image: url('https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f9.png');
              "
            />
          </v-list-item-icon>
          <v-list-item-title> Italiano </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="app-header" color="white" elevation="0">
      <v-app-bar-nav-icon class="custom-color" @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Human Design Italia</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn-toggle v-model="darkMode" mandatory>
        <v-btn :value="true" @click="darkMode = true" :class="{ 'selected-btn': darkMode, 'not-selected-btn': !darkMode }">
          <v-icon class="custom-color" v-if="isMobile">mdi-weather-night</v-icon> {{ !isMobile ? "Dark Mode" : "" }}
        </v-btn>
        <v-btn :value="false" @click="darkMode = false" :class="{ 'selected-btn': !darkMode, 'not-selected-btn': darkMode }">
          <v-icon class="custom-color" v-if="isMobile">mdi-weather-sunny</v-icon> {{ !isMobile ? "Light Mode" : "" }}
        </v-btn>
      </v-btn-toggle>


      <v-btn class="custom-color" icon @click="performCreateNewChart">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn class="custom-color" icon v-if="isAuthenticated == true"  @click="performCapture">
        <v-icon>mdi-camera</v-icon>
      </v-btn>

      <v-btn class="custom-color" icon v-if="isAuthenticated == true" @click="performLogout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="h-full">
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-dialog v-model="errorDialog" persistent max-width="500" @click:outside="errorDialog = false">
      <v-card>
        <v-card-title class="text-h5">
          {{ errorTitle }}
        </v-card-title>

        <v-card-text>
          {{ errorMessage }}
        </v-card-text>
          
        <v-card-actions>
          <v-btn color="blue-grey" v-if="true === dialogShowSubscribe" class="ma-2 white--text" @click="errorDialog = false" href="https://humandesignitalia.it/plans/human-design-plans/">
            {{ i18n.t("chart.values.startFreeTrial") }}
          </v-btn>
          <v-btn color="dark darken-1" v-if="true === dialogShowLogin" text @click="errorDialog = false"  href="https://humandesignitalia.it/my-account/">
            {{ i18n.t("chart.values.login") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="errorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style lang="sass" scoped>
.v-application
  font-family: 'Montserrat', sans-serif

.country-flag
  height: 24px
  width: 24px
  background-position: center
  background-size: contain

.app-header
  max-height: 64px
.h-full
  height: 100%
 
.dark-mode.theme--light.v-application
  background: radial-gradient(circle, #346590 0%, #173758 94%) 
.light-mode.theme--light.v-application
  background: radial-gradient(circle, #FFFFFF 0%, #CCCCCC 94%)
.v-toolbar__title  
  font-family: 'Montserrat', sans-serif
  font-weight: Semibold
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)
  background: transparent !important
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn
  border: none !important  
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default
  font-family: 'Montserrat', sans-serif
  font-weight: 600
  text-transform: uppercase
  border-radius: 28px !important
  opacity: 1 !important
  height: 30px !important
  margin-right: 8px !important
.dark-mode
  .app-header
    background-color: transparent !important 
    .v-toolbar__title
      color: #FFFFFF !important
    .v-btn__content
      color: #FFFFFF !important
    .custom-color
      color: #FFFFFF !important

  .not-selected-btn 
    background: transparent linear-gradient(102deg, #E8E8E8 0%, #848484 100%) 0% 0% no-repeat padding-box
    i.v-btn .v-icon
      color: #FFFFFF !important

  .selected-btn 
    background: transparent !important
    color: #FFFFFF !important 
    .v-btn .v-icon
      color: #FFFFFF !important
  .v-btn:before
    background: transparent !important

.light-mode
  .app-header
    background-color: transparent !important
  .not-selected-btn 
    background: transparent linear-gradient(102deg, #292D31 0%, #0F1213 100%) 0% 0% no-repeat padding-box
    color: #FFFFFF !important
    .custom-color
      color: #FFFFFF !important 
    
  .selected-btn
    background: transparent !important
    color: #000000 !important 
    .custom-color
      color: #000000 !important 

  .v-btn:before
    background: transparent !important


</style>

<script>
import { loadLanguageAsync } from "@/setup/i18n-setup";
import { i18n } from "@/setup/i18n-setup";

import html2canvas from "html2canvas";

import menuItems from "@/menu-items";
import { isAuthenticated, subscriptionInfo } from "@/setup/superagent-setup";

import router from "@/router";

export default {
  name: "App",
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  created() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 800;
    },
    loadLanguageAsync,
    performLogout() {
      window.location.href = "/logout";
    },
    downloadB64File(contentType, base64Data, fileName) {
      const linkSource = `data:${contentType};base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    performCapture() {
      if (!this.isAuthenticated) {
        this.errorTitle = i18n.t("chart.values.notAMember");
        this.errorMessage = i18n.t("chart.values.requiresMember");
        this.errorDialog = true;
        this.dialogShowSubscribe = true;
        this.dialogShowLogin = true;
        return;
      } else if (this.subscriptionInfo.canSaveChartAsImage === false){
        this.errorTitle = i18n.t("chart.values.notAPremiumMember");
        this.errorMessage = i18n.t("chart.values.requiresPremium");
        this.errorDialog = true;
        this.dialogShowSubscribe = true;
        this.dialogShowLogin = false;
        return;
      }
      const containers = document.getElementsByClassName("profile-content");

      if (containers.length > 0) {
        const chartContainer = containers[0];

        html2canvas(chartContainer).then((canvas) => {
          const t = canvas.toDataURL().replace("data:image/png;base64,", "");
          this.downloadB64File("image/png", t, "Capture_RaveChart_HDI");
        });
      }
    },
    performNavigation(item) {
      if (item.name == "library") {
        if (!this.isAuthenticated) {  
          this.errorTitle = i18n.t("chart.values.notAMember");
          this.errorMessage = i18n.t("chart.values.requiresMember");
          this.errorDialog = true;
          this.dialogShowSubscribe = true;
          this.dialogShowLogin = true;
          return;
        } else if (this.subscriptionInfo.canViewCharts === false){
          this.errorTitle = i18n.t("chart.values.notAPremiumMember");
          this.errorMessage = i18n.t("chart.values.requiresPremium");
          this.errorDialog = true;
          this.dialogShowSubscribe = true;
          this.dialogShowLogin = true;
          return;
        }
      } 

      router.replace({ path: item.href });
    },
    performCreateNewChart() {
      if (!this.isAuthenticated) {
        this.errorTitle = i18n.t("chart.values.notAMember");
        this.errorMessage = i18n.t("chart.values.requiresMember");
        this.errorDialog = true;
        this.dialogShowSubscribe = true;
        this.dialogShowLogin = true;
        return;
      } else if (this.subscriptionInfo.canCreateCharts === false){
        this.errorTitle = i18n.t("chart.values.notAPremiumMember");
        this.errorMessage = i18n.t("chart.values.requiresPremium");
        this.errorDialog = true;
        this.dialogShowSubscribe = true;
        this.dialogShowLogin = false;
        return;
      }
      router.replace({ path: "/chart/new" });
    }
  },
  data: () => ({
    menuItems,
    isAuthenticated,
    subscriptionInfo,

    errorDialog: false,
    errorTitle: "",
    errorMessage: "",

    drawer: false,
    group: null,
    darkMode: true,

    isMobile: false,
    dialogShowSubscribe: false,
    dialogShowLogin: false,
    i18n: i18n
  })
};
</script>
