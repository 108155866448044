
import { i18n } from "@/setup/i18n-setup";

const menuItems = [
    {
        name: 'home',
        label: i18n.t("menu.home"),
        href: '/',
        icon: 'mdi-account'
    },
    {
        name: 'library',
        label: i18n.t("menu.library"),
        href: '/library',
        icon: 'mdi-library'
    }
];

export default menuItems;
