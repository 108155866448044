<template>
  <div :class="{ 'slide-right': design, 'slide-left': !design }">
    <h4 class="text-overline text-center text-personality" :class="{ 'text-design': design }">
      {{ design ? $t("general.design") : $t("general.personality") }}
    </h4>
    <div
      v-if="multiple == false"
      class="list-item"
      :class="{ 'invert-order text-design': false, 'list-item-red':colorRed,  'list-item-black':!colorRed }"
      v-for="planet in planets"
      :key="(design ? 'd' : 'p') + planet.name.toLowerCase()"
      @click="openCenterDialog(planet.name, planet.details, )"
    >
      <img
        width="22"
        height="22"
        class="planet"
        :src="
          require('@/assets/icon/' +
            planet.name.replace(' ', '_').toLowerCase() +
            '.svg')
        "
      />
      <span class="gate-details">
        <span>{{ planet.details.gate + "." }}</span> <small>{{ planet.details.line }}</small>
      </span>
      <span class="fixing-state">
        <v-icon v-if="planet.details.exalted && !planet.details.detriment" class="fixing-icon">mdi-menu-up</v-icon>
        <v-icon v-if="!planet.details.exalted && planet.details.detriment" class="fixing-icon">mdi-menu-down</v-icon>
        <v-icon v-if="planet.details.exalted && planet.details.detriment" class="fixing-icon deca">mdi-decagram</v-icon>
      </span>
    </div>
    <div
      v-if="multiple == true"
      class="list-item"
      :class="{ 'invert-order text-design': design, 'list-item-red':colorRed,  'list-item-black':!colorRed }"
      v-for="planet in planets"
      :key="(design ? 'd' : 'p') + planet.name.toLowerCase()"
      @click="openCenterDialog(planet.name, planet.details[0] )"
    >
      <span>
        {{ planet.details[0].gate + "." + planet.details[0].line }}
      </span>
      <img
        width="22"
        height="22"
        class="planet"
        :src="
          require('@/assets/icon/' +
            planet.name.replace(' ', '_').toLowerCase() +
            '.svg')
        "
      />
      <span>
        {{ planet.details[1].gate + "." + planet.details[1].line }}
      </span>
    </div>
    <v-dialog v-model="centerDialog" persistent max-width="500" @click:outside="centerDialog = false">
        <v-card>
            <v-card-title class="text-h5">
            </v-card-title>
            <v-card-text v-if="gateDef">
                <h2> 
                    - {{ gateDef.title }} :
                </h2>
            </v-card-text>

            <v-card-text v-if="gateDef">
                <P v-if="gateDef.definition">
                    {{ gateDef.definition }}
                </P>
            </v-card-text>

            <v-card-text v-if="gatePlanetDetails">
                <h2> 
                    - {{ gatePlanetDetails ? gatePlanetDetails.title : "" }}
                    <img
                        width="16"
                        height="16"
                        class="planet"
                        style="filter: invert(1); margin-left: 0px;"
                        :src="
                        require('@/assets/icon/' +
                            gatePlanetDetails.img.replace(' ', '_').toLowerCase() +
                            '.svg')
                        "
                    />  :
                </h2>
                <br/>
                <p>{{  gatePlanetDetails ? gatePlanetDetails.description : ""  }}</p>
            </v-card-text>
            <v-card-text v-if="gateLineDetails">
                <h2> 
                    - Line {{gateDetails.line}} :
                </h2>
                <br/>
                <p>
                  <span class="fixing-state">
                          <v-icon v-if="gateDetails.exalted && !gateDetails.detriment" class="fixing-icon">mdi-menu-up</v-icon>
                          <v-icon v-if="!gateDetails.exalted && gateDetails.detriment" class="fixing-icon">mdi-menu-down</v-icon>
                          <v-icon v-if="gateDetails.exalted && gateDetails.detriment" class="fixing-icon deca">mdi-decagram</v-icon>
                  </span>
                  {{  gateLineDetails ? gateLineDetails : ""  }}
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="centerDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" persistent max-width="500" @click:outside="errorDialog = false">
        <v-card>
            <v-card-title class="text-h5">
            {{ errorTitle }}
            </v-card-title>

            <v-card-text>
            {{ errorMessage }}
            </v-card-text>

            <v-card-actions>
            <v-btn v-if="true === dialogShowSubscribe" color="blue-grey" class="ma-2 white--text" @click="errorDialog = false" href="https://humandesignitalia.it/plans/human-design-plans/">
                {{ i18n.t("chart.values.startFreeTrial") }}
            </v-btn>
            <v-btn v-if="true === dialogShowLogin" color="dark darken-1" text @click="errorDialog = false"  href="https://humandesignitalia.it/my-account/">
                {{ i18n.t("chart.values.login") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="errorDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <br>
    <br>
  </div>
</template>

<style lang="sass" scoped>
.v-card__text, .v-card-title
    font-family: 'Montserrat', sans-serif
@-webkit-keyframes slide-right
    0%
      opacity: 0
      margin-left: -30px
    100%
      opacity: 1
      margin-left: 0

@keyframes slide-right
    0%
      opacity: 0
      margin-left: -30px
    100%
      opacity: 1
      margin-left: 0

@-webkit-keyframes slide-left
    0%
      opacity: 0
      margin-right: -30px
    100%
      opacity: 1
      margin-right: 0

@keyframes slide-left
    0%
      opacity: 0
      margin-right: -30px
    100%
      opacity: 1
      margin-right: 0

.slide-right
  animation: 2s slide-right ease-in-out

.slide-left
  animation: 2s slide-left ease-in-out

.list-item
    width: 95px
    height: 43px
    max-width: 95px
    background: rgb(9,9,9)
    color: #fff
    margin: 10px
    display: flex
    justify-content: space-around
    align-items: center
    border-radius: 4px
    .fixing-state
      min-width: 20px

      .fixing-icon
        color: white
        &.deca
          font-size: 17px
          margin-left: 5px
    img
      width: 20px
      height: 20px
      opacity: .5   
    .fixing-state 
      opacity: .5      
    .gate-details
      font-family: 'Montserrat', sans-serif
      font-weight: 500
      display: flex
      align-items: baseline
      color: #FFFFFF
      span
        font-size: 18px

.list-item-red
  background: linear-gradient(114deg, #EA3927 0%, #AA1809 100%)
.list-item-black
    background: linear-gradient(114deg, #292D31 0%, #0F1213 100%)          
.invert-order
    flex-direction: row-reverse

.dark-mode    
  .text-design
      font-family: 'Montserrat', sans-serif
      font-weight: Semibold
      color: #d9473d
  .text-personality
      font-family: 'Montserrat', sans-serif
      font-weight: Semibold
      color: #ffffff
.light-mode    
  .text-design
      font-family: 'Montserrat', sans-serif
      font-weight: Semibold
      color: #000000
  .text-personality
      font-family: 'Montserrat', sans-serif
      font-weight: Semibold
      color: #000000
.planet
    margin: 0 .5rem

</style>

<script>
import { i18n } from "@/setup/i18n-setup";
import { isAuthenticated, subscriptionInfo } from "@/setup/superagent-setup";

export default {
  name: "rave-planets",
  props: ["design", "planets", "multiple", "colorRed"],
  data: () => (
    {
      centerDialog: false,
      gateDetails: "",
      gatePlanetDetails: "",
      gateLineDetails: "",
      gateDef: null,
      varifyAccess(featAccess) {
          if (!this.isAuthenticated) {
              this.errorTitle = i18n.t("chart.values.notAMember");
              this.errorMessage = i18n.t("chart.values.requiresMember");
              this.errorDialog = true;
              this.dialogShowSubscribe = true;
              this.dialogShowLogin = true;
              return false;
          } else if (featAccess === false){
              this.errorTitle = i18n.t("chart.values.notAPremiumMember");
              this.errorMessage = i18n.t("chart.values.requiresPremium");
              this.errorDialog = true;
              this.dialogShowSubscribe = true;
              this.dialogShowLogin = false;
              return false;
          }
          return true;
      },
      openCenterDialog(title, details) {
          //if (!this.varifyAccess(this.subscriptionInfo.canViewChartGateDetails)) {
          //    return;
          //}

          let pDefs = [];
          [
              "Sun", "Earth", "Moon", "NorthNode", "SouthNode", 
              "Mercury", "Venus", "Mars", "Jupiter", "Saturn",
              "Uranus", "Neptune", "Pluto"
          ].forEach((p) => pDefs[p] = {
              img: i18n.t(`chart.planetsDefs.${p}.img`),
              title: i18n.t(`chart.planetsDefs.${p}.title`),
              description: i18n.t(`chart.planetsDefs.${p}.description`)
          });

          const planetsDefs = pDefs;

          let lDefs = [];
          for (let l = 1; l <= 6; l++) {
              lDefs[l] = i18n.t(`chart.linesDefs.${l}`);
          }
          const linesDefs = lDefs;

          let cDefs = [];
          for (let c = 1; c <= 64; c++) {
              cDefs[c] = {
                  number: c,
                  title: i18n.t(`chart.centersGatesDefs.${c}.title`),
                  definition: i18n.t(`chart.centersGatesDefs.${c}.definition`),
              };
          }
          const centersGatesDefs = cDefs;
        
          this.gateDetails = details;
          this.gateDef = centersGatesDefs[this.gateDetails.gate];
          this.gatePlanetDetails = planetsDefs[title];
          this.gateLineDetails = linesDefs[this.gateDetails?.line] || null;
          this.centerDialog = true;
      },

      errorDialog: false,
      errorTitle: "",
      errorMessage: "",
      isAuthenticated,
      subscriptionInfo,
      dialogShowSubscribe: false,
      dialogShowLogin: false,
          
      i18n: i18n
    }
  )
};
</script>