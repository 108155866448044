<template>
  <v-container fluid :class="{ 'h-full': isMobile }" v-if="!isLoading">
    <v-row :class="{ 'h-full': isMobile }">
      <v-col v-if="!isMobile" cols="12" md="8" class="d-none d-lg-block profile-frame">
        
        <v-row class="justify-center">
          <v-col cols="12" md="10" class="profile-container">
            <rave-planets
              :multiple="false"
              :design="true"
              :planets="designPlanets"
              :colorRed="true"
            />

            <rave-profile2
              v-if="designPlanets && !isMobile"
              :design="designPlanets"
              :personality="personalityPlanets"
              :gates="activeGates"
              :centers="activeCenters"
              :activeCentersGroups="activeCentersGroups"
              viewBox="0 0 1990.119 2194.68"
              :mobileView="false"
              :mobileNavValue="mobileNavValue"
              @update-mobileNavValue="mobileNavValue = $event"
              :variable="variable"
            ></rave-profile2>

            <rave-planets 
              :multiple="false" 
              :planets="personalityPlanets"
              :colorRed="false"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!isMobile" cols="0" md="4" class="d-none d-lg-block card-props">
        <v-sheet height="100%">
          <properties-panel :options="properties" />
        </v-sheet>
      </v-col>

      <v-col v-if="isMobile" cols="12" class="d-block d-lg-none profile-frame">
        
        <rave-profile2
          v-if="isMobile && mobileNavValue === 0"
          :design="designPlanets"
          :personality="personalityPlanets"
          :gates="activeGates"
          :centers="activeCenters"
          :activeCentersGroups="activeCentersGroups"
          :viewBox="isTablet ? '0 0 1990.119 2194.68' : '320 620 1352.589 1205.51'"
          :mobileView="true"
          :mobileNavValue="mobileNavValue"
          @update-mobileNavValue="mobileNavValue = $event"
          :variable="variable"
        />

        <v-row class="justify-center showBodygraph" v-if="isMobile && mobileNavValue === 1">
          <v-btn class="ma-2 white--text" outlined color="white" @click="mobileNavValue = 0">
            {{ i18n.t(mobileNavValue === 0 ? "chart.values.gates" : "chart.values.bodygraph") }}
          </v-btn> 
        </v-row>
        <v-row justify="center" v-if="isMobile && mobileNavValue === 1">
          <rave-planets :design="true" :planets="designPlanets" :multiple="false" :colorRed="true" />
          <rave-planets :planets="personalityPlanets" :multiple="false" :colorRed="false" />
        </v-row>

        <v-col cols="12" class="d-block d-lg-none card-props" v-if="isMobile && mobileNavValue === 2">
          <v-sheet height="100%">
            <properties-panel :options="properties" />
          </v-sheet>
        </v-col>
      </v-col>
    </v-row>

    <v-bottom-navigation
      v-model="mobileNavValue"
      :input-value="isMobile"
      fixed
      color="indigo"
    >
      <v-btn>
        <span>{{ $t("general.chart") }}</span>

        <v-icon>mdi-account</v-icon>
      </v-btn>

      <v-btn>
        <span>
          {{ $t("general.properties") }}
        </span>

        <v-icon>mdi-card-account-details</v-icon>
      </v-btn>

      <v-btn>
        <span>
          {{ $t("general.details") }}
        </span>

        <v-icon>mdi-text-box-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-dialog v-model="errorDialog" persistent max-width="500" @click:outside="errorDialog = false">
      <v-card>
        <v-card-title class="text-h5">
          {{ errorTitle }}
        </v-card-title>

        <v-card-text>
          {{ errorMessage }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="errorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="sass" scoped>
.profile-frame
  background: transparent
  border-radius: .8rem
.profile-container
  max-height: 90vh
  display: flex
  justify-content: center
.h-full
  height: 100%
  
.light-mode
  .showBodygraph
    button
      color: #000000 !important
</style>

<script>
import { i18n } from "@/setup/i18n-setup";
import superagent from "@/setup/superagent-setup";

import RaveProfile2 from "@/components/RaveProfile2.vue";
import RavePlanets from "@/components/RavePlanets.vue";
import PropertiesPanel from "@/components/PropertiesPanel.vue";

export default {
  name: "Home",
  components: {
    RaveProfile2,
    RavePlanets,
    PropertiesPanel,
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  created() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });

    superagent
      .get(process.env.VUE_APP_BASE_URL + "bgapi/v1/charts/daily")
      .then((res) => {
        if (res.body.status !== "ok") {
          this.errorTitle = i18n.t(res.body.subject);
          this.errorMessage = i18n.t(res.body.message);

          this.errorDialog = true;
          return;
        }

        let details = res.body.out.chart.details;
        
        let profile;
        details.forEach((item) => {
          item.label = i18n.t(item.label);

          item.rows.forEach((row) => {
            if (item.name === "general" && row.name === "profile") {
              profile = row.value;
            }
            if (item.name === "advanced" && row.name === "variable") {
              this.variable   = row.value;
            }
            row.name = i18n.t("chart.props." + row.name);

            if (row.i18n) {
              row.value = row.value ? i18n.t("chart.values." + row.value) : "";
            }
          });
        });

        this.properties = res.body.out.chart.details;
        
        this.designPlanets = res.body.out.chart.designStructure.planets;
        this.personalityPlanets =
          res.body.out.chart.personalityStructure.planets;
        
        let crossesValue = null;
        let sunPersonality = this.personalityPlanets?.find((p) => p.name === "Sun")?.details.gate;
        let earthPersonality = this.personalityPlanets?.find((p) => p.name === "Earth")?.details.gate;
        let sunDesign = this.designPlanets?.find((p) => p.name === "Sun")?.details.gate;
        let earthDesign = this.designPlanets?.find((p) => p.name === "Earth")?.details.gate;
        if (["1/3", "1/4", "2/4", "2/5", "3/5", "3/6", "4/6"].includes(profile)) {
          // Right angle, Personal Destiny
          crossesValue = i18n.t(`chart.crosses.destro_${sunPersonality}_${earthPersonality}_${sunDesign}_${earthDesign}`);
        } else if (["4/1"].includes(profile)) {
          // Juxtaposition, fixed fate
          crossesValue = i18n.t(`chart.crosses.gius_${sunPersonality}_${earthPersonality}_${sunDesign}_${earthDesign}`);
        } else if (["5/1", "5/2", "6/2", "6/3"].includes(profile)) {
          // Left angle, Transpersonal Karma
          crossesValue = i18n.t(`chart.crosses.sini_${sunPersonality}_${earthPersonality}_${sunDesign}_${earthDesign}`);
        }

        if (crossesValue) {
          details[0].rows.push({
            name: i18n.t("chart.props.incarnationCross"),
            value: crossesValue
          });
        }


        this.activeGates = [];
        let activeGates = res.body.out.chart.activeGates;
        activeGates.forEach((gate) => {
          let fp = this.personalityPlanets.find((x)=>x.details.gate === gate);
          let fd = this.designPlanets.find((x)=>x.details.gate === gate);
          this.activeGates.push({
             gate,
             color: fp && fd ? "#222423" : fp ? "#222423" : "#ff1709",
             redIfIsSun:  fp && fd 
          });
        });

        this.activeCenters = res.body.out.chart.activeCenters;        
        
        // resolve definition groups 
        let allCenters = [
            {startCenter: 'anja', endCenter: 'head', channels: ["63-4", "61-24", "64-47"]},
            {startCenter: 'throat', endCenter: 'anja', channels: ["11-56", "43-23", "17-62"]},
            {startCenter: 'throat', endCenter: 'splenic', channels: ["16-48", "20-57"]},
            {startCenter: 'throat', endCenter: 'solar_plexus', channels: ["12-22", "35-36"]},
            {startCenter: 'throat', endCenter: 'ego', channels: ["45-21"]},
            {startCenter: 'throat', endCenter: 'sacral', channels: ["20-34"]},
            {startCenter: 'splenic', endCenter: 'g', channels: ["10-57"]},
            {startCenter: 'throat', endCenter: 'g', channels: ["33-13", "8-1", "31-7"]},
            {startCenter: 'g', endCenter: 'sacral', channels: ["2-14", "15-5", "46-29", "10-34"]},
            {startCenter: 'ego', endCenter: 'g', channels: ["25-51"]},
            {startCenter: 'ego', endCenter: 'solar_plexus', channels: ["40-37"]},
            {startCenter: 'ego', endCenter: 'splenic', channels: ["26-44"]},
            {startCenter: 'solar_plexus', endCenter: 'root', channels: ["49-19"]},
            {startCenter: 'root', endCenter: 'solar_plexus', channels: ["41-30", "39-55"]},
            {startCenter: 'splenic', endCenter: 'root', channels: ["32-54","18-58", "28-38"]},
            {startCenter: 'sacral', endCenter: 'root', channels: ["9-52", "3-60", "42-53"]},,
            {startCenter: 'sacral', endCenter: 'solar_plexus', channels: ["59-6"]},
            {startCenter: 'splenic', endCenter: 'sacral', channels: ["50-27"]}
        ];
        
        
        allCenters.map((connection) => {
            const tempChannels = connection.channels;
            connection.channels = tempChannels.filter((channel) => this.isActiveChannel(channel));
        });
        // this is the connected possible centers 
        let allConnectedCenters = allCenters.filter((connection)=> connection.channels.length > 0);    
        
        // use allConnectedCenters to groupe the connected centers in this.activeCentersGroups

        allConnectedCenters.forEach((connection) => {
          const { startCenter, endCenter } = connection;
          const startGroup = this.findGroup(startCenter);
          const endGroup = this.findGroup(endCenter);

          if (startGroup && endGroup) {
            if (startGroup !== endGroup) {
              this.activeCentersGroups[startGroup] = this.activeCentersGroups[startGroup].concat(this.activeCentersGroups[endGroup]);
              delete this.activeCentersGroups[endGroup];
            }
          } else if (startGroup) {
            this.activeCentersGroups[startGroup].push(endCenter);
          } else if (endGroup) {
            this.activeCentersGroups[endGroup].push(startCenter);
          } else {
            const newGroup = Object.keys(this.activeCentersGroups).find((group) => this.activeCentersGroups[group].length === 0);
            this.activeCentersGroups[newGroup].push(startCenter, endCenter);
          }
        });
        
        // end

      }).finally((_) => {
        this.isLoading = false;
      });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1264;
      this.isTablet = window.innerWidth >= 599;
    },
    isActiveChannels(channels) {
      let connected = false;
      channels.forEach((channel) => {
        if (this.isActiveChannel(channel)) connected = true;
      });
      return connected;
    },
    isActiveChannel(channel) {
      let connected = false;
      let gates = channel.split("-");

      let fp0 = this.personalityPlanets.find((x)=>x.details.gate == gates[0]);
      let fd0 = this.designPlanets.find((x)=>x.details.gate == gates[0]);
      let fp1 = this.personalityPlanets.find((x)=>x.details.gate == gates[1]);
      let fd1 = this.designPlanets.find((x)=>x.details.gate == gates[1]);

      if ( (((fp0 && fp1) || (fp0 && fd1)) || ((fd0 && fp1) || (fd0 && fd1) ))) connected = true;
      return connected;
    },
    findGroup(center) {
      for (let group in this.activeCentersGroups) {
        if (this.activeCentersGroups[group].includes(center)) {
          return group;
        }
      }
      return null;
    }
  },
  data: () => ({
    isLoading: true,
    errorDialog: false,
    errorTitle: "",
    errorMessage: "",

    isMobile: false,
    isTablet: true,
    mobileNavValue: 0,

    properties: [],
    designPlanets: undefined,
    personalityPlanets: undefined,
    activeGates: undefined,
    activeCenters: undefined,
    activeCentersGroups: {
      group1: [],
      group2: [],
      group3: [],
      group4: []
    },
    i18n: i18n,
    variable: ""
  }),
};
</script>
