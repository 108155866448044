<template>
  <v-card height="100%">

    <h4 v-if="extra !== undefined && extra.length > 0" class="text-overline text-center">
      {{ $t('chart.propFirstConnection') }}
    </h4>

    <v-tabs v-model="tab" background-color="transparent">
      <v-tab v-for="option in options" :key="option.name" @click="varifyAccess(option.name)">
        {{ option.label }} 
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="option in options" :key="option.name" >
        <v-data-table
          v-if="hasAccess(option.name)"
          hide-default-header
          hide-default-footer
          disable-pagination
          disable-sort
          :headers="headers"
          :items="option.rows"
        >
        </v-data-table>

        <v-container v-if="!hasAccess(option.name)">
          <v-row class="mt-2 px-2">
            <h2 class="text-h4">
              {{ errorTitle }}
            </h2>
          </v-row>
          <br>
          <v-row class="mt-2 px-2 text-description">
            {{ errorMessage }}
          </v-row>
        </v-container>

      </v-tab-item>
    </v-tabs-items>

    <h4 v-if="extra !== undefined && extra.length > 0" class="text-overline text-center">
      {{ $t('chart.propSecondConnection') }}
    </h4>

    <v-tabs v-if="extra !== undefined && extra.length > 0" v-model="extraTab" hide-slider>
      <v-tab v-for="option in extra" :key="option.name" @click="varifyAccess(option.name)">
        {{ option.label }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-if="extra !== undefined && extra.length > 0" v-model="extraTab">
      <v-tab-item v-for="option in extra" :key="option.name">
        <v-data-table
          hide-default-header
          hide-default-footer
          disable-pagination
          disable-sort
          :headers="headers"
          :items="option.rows"
        >
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <br>
    <v-dialog v-model="errorDialog" persistent max-width="500" @click:outside="errorDialog = false">
        <v-card>
            <v-card-title class="text-h5">
            {{ errorTitle }}
            </v-card-title>

            <v-card-text>
            {{ errorMessage }}
            </v-card-text>

            <v-card-actions>
            <v-btn v-if="true === dialogShowSubscribe" color="blue-grey" class="ma-2 white--text" @click="errorDialog = false" href="https://humandesignitalia.it/plans/human-design-plans/">
                {{ i18n.t("chart.values.startFreeTrial") }}
            </v-btn>
            <v-btn v-if="true === dialogShowLogin" color="dark darken-1" text @click="errorDialog = false"  href="https://humandesignitalia.it/my-account/">
                {{ i18n.t("chart.values.login") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="errorDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</template>
<style lang="sass" >
.card-props
  .v-tabs
    background-color: transparent
  .v-tabs-slider-wrapper
    display: none
  .v-sheet.v-card:not(.v-sheet--outlined), .v-sheet:not(.v-sheet--outlined)
    box-shadow: none !important
  .theme--light.v-sheet
    background-color: transparent !important
  .theme--light.v-tabs-items, .theme--light.v-data-table
      background-color: transparent !important
      color: rgba(0, 0, 0, 0.87)

.dark-mode
  .text-h4, .text-description
    color: #FFFFFF !important
.light-mode
  .text-h4, .text-description
    color: #000000 !important  

.dark-mode
  .card-props
    .v-data-table__wrapper
      tr > td:first-child
        font-family: 'Montserrat', sans-serif
        font-weight: 400
        opacity: 0.55
        color: #FFFFFF
      tr > td:nth-child(2)
        font-family: 'Montserrat', sans-serif
        font-weight: 600
        color: #FFFFFF
    .v-tab
      font-family: 'Montserrat', sans-serif
      font-weight: 600
      color: #FFFFFF !important
      opacity: 0.3
      text-decoration: none !important
    .v-tab:hover
      color: #FFFFFF !important
      opacity: 1
    .v-tab.v-tab--active
      color: #FFFFFF !important
      opacity: 1
    .theme--light.v-tabs-items, .theme--light.v-data-table
        color: rgba(0, 0, 0, 0.87)
    .v-data-table__wrapper tr:hover
        background-color: transparent !important
    .v-data-table__wrapper
      .text-start
        font-family: 'Montserrat', sans-serif
        font-weight: 400
        color: #FFFFFF
        opacity: 0.55
        border-bottom: 1px solid rgba(255, 255, 255, 0.88) !important 
      .text-end
        font-family: 'Montserrat', sans-serif
        font-weight: 600
        color: #FFFFFF
        text-transform: uppercase
        border-bottom: 1px solid rgba(255, 255, 255, 0.44) !important 
    .v-slide-group__next .theme--light.v-icon, .v-slide-group__prev .theme--light.v-icon  
        color: #FFFFFF
        opacity: 0.55  
.light-mode
  .card-props
    .v-data-table__wrapper
      tr > td:first-child
        font-family: 'Montserrat', sans-serif
        font-weight: 400
        opacity: 0.55
        color: #000000
      tr > td:nth-child(2)
        font-family: 'Montserrat', sans-serif
        font-weight: 600
        color: #000000
    .v-tab
      font-family: 'Montserrat', sans-serif
      font-weight: 600
      color: #000000 !important
      opacity: 0.3
      text-decoration: none !important
    .v-tab:hover
      color: #000000 !important
      opacity: 1
    .v-tab.v-tab--active
      color: #000000 !important
      opacity: 1
    .theme--light.v-tabs-items, .theme--light.v-data-table
        color: rgba(0, 0, 0, 0.87)
    .v-data-table__wrapper tr:hover
        background-color: transparent !important
    .v-data-table__wrapper
      .text-start
        font-family: 'Montserrat', sans-serif
        font-weight: 400
        color: #000000
        opacity: 0.55 
        border-bottom: 1px solid rgba(0, 0, 0, 0.88) !important 
      .text-end
        font-family: 'Montserrat', sans-serif
        font-weight: 600
        color: #000000
        text-transform: uppercase      
        border-bottom: 1px solid rgba(0, 0, 0, 0.44) !important   

.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev 
  display: none !important

</style>
<script>

import { i18n } from "@/setup/i18n-setup";
import { isAuthenticated, subscriptionInfo } from "@/setup/superagent-setup";

export default {
  name: "properties-panel",
  props: ["options", "extra"],
  data: () => ({
    tab: null,
    extraTab: null,
    hasAccess(featAccess){
      let v = false;
      if (featAccess === "general") {
        v = this.subscriptionInfo.canViewChartGeneralDetails.active;
      } else if (featAccess === "advanced") {
        v = this.subscriptionInfo.canViewChartAdvancedDetails.active;
      } else if (featAccess === "activations") {
        v = this.subscriptionInfo.canViewChartActivationsDetails.active;
      } else if (featAccess === "birthData") {
        v = this.subscriptionInfo.canViewChartBirthDataDetails.active;
      } 
      return v;
    },
    varifyAccess(featAccess) {
      const v = this.hasAccess(featAccess);
      if (!this.isAuthenticated && v === false) {
          this.errorTitle = i18n.t("chart.values.notAMember");
          this.errorMessage = i18n.t("chart.values.requiresMember");
          this.errorDialog = true;
          this.dialogShowSubscribe = true;
          this.dialogShowLogin = true;
          return false;
      } else if (v === false){
          this.errorTitle = i18n.t("chart.values.notAPremiumMember");
          this.errorMessage = i18n.t("chart.values.requiresPremium");
          this.errorDialog = true;
          this.dialogShowSubscribe = true;
          this.dialogShowLogin = false;
          return false;
      }
      return true;
    },
    headers: [
      {
        text: "name",
        align: "start",
        value: "name",
      },
      {
        text: "value",
        align: "end",
        value: "value",
      },
    ],
    errorDialog: false,
    errorTitle: "",
    errorMessage: "",
    isAuthenticated,
    subscriptionInfo,
    dialogShowSubscribe: false,
    dialogShowLogin: false,
        
    i18n: i18n
  }),
};
</script>