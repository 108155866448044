import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/library',
    name: 'library',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "library" */ '../views/LibraryView.vue')
  },
  {
    path: '/chart/new',
    name: 'create-chart',
    component: () => import(/* webpackChunkName: "create-chart" */ '../views/CreateChartView.vue')
  },
  {
    path: '/chart/:type/:id',
    name: 'view-chart',
    component: () => import(/* webpackChunkName: "view-chart" */ '../views/ChartView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
