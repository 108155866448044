
const defaults = require('superagent-defaults');
const superagent = defaults();

const accessToken = document.cookie.match('(^|;)\\s*__app_token\\s*=\\s*([^;]+)')?.pop() || ''
let auth = false;

if (accessToken.length > 0) {
    superagent.set('X-BgAPI-AuthToken', accessToken);
    auth = true;
}

if (process.env.NODE_ENV === 'development') {
    auth = true;
}

export const isAuthenticated = auth;
export const subscriptionInfo = {
    canCreateCharts: true,
    canViewCharts: true,
    canDeleteCharts: true,
    canSaveChartAsImage: true,
    canViewChartNormalMode: true,
    canViewChartMandalaMode: true,
    canViewChartDetails: true,
    canViewChartCenterDetails: true,
    canViewChartGateDetails: true,
    canViewChartGeneralDetails: {
        active: true,
        canViewType: true,
        canViewStrategy: true,
        canViewSignature: true,
        canViewNonSelfTheme: true,
        canViewAuthority: true,
        canViewDefinition: true,
        canViewProfile: true
    },
    canViewChartAdvancedDetails: {
        active: true,
        canViewVariable: true,
        canViewDetermination: true,
        canViewCognition: true,
        canViewEnvironment: true,
        canViewMotivation: true,
        canViewView: true
    },
    canViewChartActivationsDetails: {
        active: true,
        canViewLineOne: true,
        canViewLineTwo: true,
        canViewLineThree: true,
        canViewLineFour: true,
        canViewLineFive: true,
        canViewLineSix: true
    },
    canViewChartBirthDataDetails: {
        active: true,
        canViewTimezone: true,
        canViewDateOfBirth: true,
        canViewPersonalityDate: true,
        canViewDesignDate: true,
    },
};

export default superagent;